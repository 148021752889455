:root {
  --background: #c1d72e;
  --foreground: #fefefe;
}

body {
  background-color: var(--background);
  color: var(--foreground)
}

h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 4rem;
}

.keyboard {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.3rem;
  justify-content: center;
}

.keyboard .key a {
  cursor: pointer;
  display: block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  background-color: var(--foreground);
  border: 1px solid var(--foreground);
  text-align: center;
  color: var(--background);
  font-size: 1.25rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
}

.keyboard .key a:hover,
.keyboard .key a:active {
  background-color: var(--background);
  color: var(--foreground);
}

.keyboard .key a.disabled {
  cursor: default;
  opacity: 0.4;
}

h3 {
  font-size: 2rem;
  letter-spacing: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.stats {
  margin: 2rem auto;
  font-size: 1.25rem;
  text-align: center;
}
